.primary___IuFl-,
.link___jEmBr {
  color: var(--text-primary, #0e0e0e);
  font-weight: 600;
  font-family: 'circular', helvetica, sans-serif;
  font-size: inherit;
  /* stylelint-disable-line sh-waqar/declaration-use-variable */
  line-height: normal;
  cursor: pointer;
  text-decoration: underline;
  letter-spacing: normal;
  font-stretch: normal;
  font-style: normal;
}
.primary___IuFl-:hover,
.link___jEmBr:hover {
  color: var(--text-secondary, #4a4a4a);
  text-decoration: underline;
}
.primary___IuFl-:active,
.link___jEmBr:active {
  color: var(--text-secondary, #4a4a4a);
  text-decoration: underline;
}
.newarrow___1f3LA {
  vertical-align: middle;
  padding-right: 0.2em;
  padding-bottom: 0.1em;
}
.newarrow___1f3LA::after {
  left: 0.2em;
  position: relative;
  margin-right: -1.5em;
}
.secondary___2CYKz {
  cursor: pointer;
  color: var(--interactive-primary, #0f5c82);
  text-decoration: underline;
}
.secondary___2CYKz:hover {
  color: var(--interactive-primary-hover, #4a4a4a);
}
.tertiary___2Rrna {
  cursor: pointer;
  color: #ffffff;
  text-decoration: underline;
}
.tertiary___2Rrna:hover {
  color: #dbdbdb;
}
.disabled___1Fazi {
  cursor: pointer;
  color: #b7b7b7;
  text-decoration: underline;
  cursor: auto;
}
.disabled___1Fazi:hover {
  color: #b7b7b7;
}
.disabled___1Fazi:hover,
.disabled___1Fazi:active,
.disabled___1Fazi:visited,
.disabled___1Fazi::after,
.disabled___1Fazi::before {
  color: #b7b7b7;
}
.active___3eeHN {
  text-decoration: underline;
}
.danger___2J3yZ {
  cursor: pointer;
  color: #c7305b;
  text-decoration: underline;
}
.danger___2J3yZ:hover {
  color: #cf4f74;
}
.black___3jqdM {
  color: var(--text-primary, #0e0e0e);
}
.inline___2KDmj {
  letter-spacing: normal;
}
.subtle___D4kGz {
  font-weight: 400;
}
.bold___1i8Dz {
  font-weight: 700;
}
.inherit___Zgbe_ {
  font-size: inherit;
}
.extraSmall___1C4ET {
  font-size: 12px;
}
.smaller___W_ZyD {
  font-size: 14px;
}
.sm___1v-g3,
.small___3NYTu {
  font-size: 16px;
}
.md___1cv5G,
.regular___2TzWM {
  font-size: 20px;
}
.large___1ssJ0,
.lg___PGecy {
  font-size: 24px;
}
.larger___36_PU,
.xl___2xM5h {
  font-size: 32px;
}
.semiHuge___2_D_N {
  font-size: 40px;
}
.huge___1Hac5 {
  font-size: 56px;
}
.button___2DAOp {
  outline: none;
  border: none;
  padding: 0;
  background-color: transparent;
}
.button___2DAOp:focus-visible {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: 1px;
}
.noUnderline___1ZI9B:not(:hover) {
  text-decoration: none;
}

.zola-ui.circle-icon {
  border-radius: 50%;
}
.zola-ui.circle-icon.cream {
  background-color: #f6f5f0;
}
.zola-ui.circle-icon.white {
  background-color: #ffffff;
}
.zola-ui.circle-icon.transparent {
  background-color: transparent;
}

.v2-button {
  border: 1px solid transparent;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  border-radius: 100px;
  height: 52px;
  padding-left: 16px;
  padding-right: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}
@media (min-width: 768px) {
  .v2-button {
    height: 44px;
  }
}
.v2-button.button--compact {
  font-size: 14px;
  height: 38px;
}
.v2-button.button--fullWidth {
  width: 100%;
  flex-basis: 100%;
}
.v2-button:disabled {
  cursor: default;
}
.v2-button:focus {
  outline: 5px auto -webkit-focus-ring-color;
}
a.v2-button {
  justify-content: center;
  align-items: center;
  user-select: none;
  text-align: center;
  text-decoration: none;
}
a.v2-button:active,
a.v2-button:focus,
a.v2-button:hover {
  text-decoration: none;
}
.v2-button[role='link'] {
  background-color: transparent;
  border-radius: unset;
}
.v2-button.primary-button {
  background-color: #0e0e0e;
  color: #ffffff;
}
.v2-button.primary-button:hover {
  color: #ffffff;
  background-color: #4a4a4a;
}
.v2-button.primary-button:active {
  background-color: #0e0e0e;
}
.v2-button.primary-button:disabled {
  background-color: #b7b7b7;
}
.v2-button.secondary-button {
  background-color: #ffffff;
  color: #0e0e0e;
  border-color: #0e0e0e;
}
.v2-button.secondary-button:hover {
  background-color: #f3f3f3;
  color: #0e0e0e;
  border-color: #0e0e0e;
}
.v2-button.secondary-button:active {
  background-color: #ffffff;
  color: #0e0e0e;
}
.v2-button.secondary-button:disabled {
  background-color: #ffffff;
  color: #b7b7b7;
  border-color: #b7b7b7;
}
.v2-button.tertiary-button {
  background-color: #ffffff;
  color: #c7305b;
  border-color: #c7305b;
}
.v2-button.tertiary-button:hover {
  background-color: #fcf5f7;
}
.v2-button.tertiary-button:active {
  background-color: #ffffff;
}
.v2-button.tertiary-button:disabled {
  background-color: #fcf5f7;
  color: #eec1ce;
  border-color: #eec1ce;
}
.v2-button.transparent-button {
  background-color: transparent;
  color: #ffffff;
  border-color: #ffffff;
}
.v2-button.transparent-button:disabled {
  background-color: #f3f3f3;
  color: #b7b7b7;
}
.v2-button.danger-button {
  background-color: #c7305b;
  color: #ffffff;
}
.v2-button.danger-button:hover {
  background-color: #cf4f74;
}
.v2-button.danger-button:active {
  background-color: #c7305b;
}
.v2-button.danger-button:disabled {
  background-color: #eec1ce;
}

.zola-ui.link {
  color: var(--text-primary, #0e0e0e);
  cursor: pointer;
  font-weight: 600;
  font-size: inherit;
  text-decoration: underline;
  letter-spacing: normal;
  line-height: normal;
  font-stretch: normal;
  font-style: normal;
}
.zola-ui.link .zola-ui-icon {
  padding-right: 5px;
}
.zola-ui.link.link-danger {
  color: #c7305b;
}
.zola-ui.link.link-danger:hover,
.zola-ui.link.link-danger.hover {
  color: #cf4f74;
}
.zola-ui.link.link-black {
  color: var(--text-primary, #0e0e0e);
}
.zola-ui.link:hover,
.zola-ui.link.hover {
  color: var(--text-secondary, #4a4a4a);
  text-decoration: underline;
}
.zola-ui.link:hover .zola-ui-icon,
.zola-ui.link.hover .zola-ui-icon {
  text-decoration: none;
}
.zola-ui.link:active,
.zola-ui.link.active,
.zola-ui.link.selected {
  color: var(--text-secondary, #4a4a4a);
  text-decoration: underline;
}
.zola-ui.link:active.link-arrow::after,
.zola-ui.link.active.link-arrow::after,
.zola-ui.link.selected.link-arrow::after,
.zola-ui.link:active.link-arrow::before,
.zola-ui.link.active.link-arrow::before,
.zola-ui.link.selected.link-arrow::before {
  fill: var(--text-secondary, #4a4a4a);
}
.zola-ui.link.inline {
  letter-spacing: normal;
}
.zola-ui.link.subtle {
  font-weight: 400;
}
.zola-ui.link.secondary {
  color: #0f5c82;
}
.zola-ui.link.secondary:hover,
.zola-ui.link.secondary.hover {
  color: #4a4a4a;
}
.zola-ui.link.secondary.link-arrow::after,
.zola-ui.link.secondary.link-arrow::before {
  background-image: #0f5c82;
}
.zola-ui.link.secondary.link-arrow:hover::after,
.zola-ui.link.secondary.link-arrow:hover::before {
  background-image: #4a4a4a;
}
.zola-ui.link.tertiary {
  color: #ffffff;
}
.zola-ui.link.tertiary:hover,
.zola-ui.link.tertiary.hover {
  color: #dbdbdb;
}
.zola-ui.link.tertiary.link-arrow::after,
.zola-ui.link.tertiary.link-arrow::before {
  background-image: #ffffff;
}
.zola-ui.link.tertiary.link-arrow:hover::after,
.zola-ui.link.tertiary.link-arrow:hover::before {
  background-image: #dbdbdb;
}
.zola-ui.link.extra-small {
  font-size: 12px;
}
.zola-ui.link.smaller {
  font-size: 14px;
}
.zola-ui.link.sm,
.zola-ui.link.small {
  font-size: 16px;
}
.zola-ui.link.md,
.zola-ui.link.regular {
  font-size: 20px;
}
.zola-ui.link.large,
.zola-ui.link.lg {
  font-size: 24px;
}
.zola-ui.link.larger,
.zola-ui.link.xl {
  font-size: 32px;
}
.zola-ui.link.semi-huge {
  font-size: 40px;
}
.zola-ui.link.huge {
  font-size: 56px;
}
.zola-ui.link.no-underline:not(:hover) {
  text-decoration: none;
}
.zola-ui.link .new-arrow {
  vertical-align: middle;
  padding-right: 0.2em;
  padding-bottom: 0.1em;
}
.zola-ui.link .new-arrow::after {
  left: 0.2em;
  position: relative;
  margin-right: -0.8em;
}
button.zola-ui.link {
  outline: none;
  border: none;
  padding: 0;
  background-color: transparent;
}
button.zola-ui.link:focus-visible {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: 1px;
}

